import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Address from '../../types/Address';
import EmailIcon from '../../components/Icons/EmailIcon';
import LocationIcon from '../../components/Icons/LocationIcon';
import PhoneIcon from '../../components/Icons/PhoneIcon';
import Styles from './Contact.module.scss';

interface Props {
  address?: Address;
  email?: string;
  pageTitle: string;
  phone?: string;
}

const defaultProps = {
  address: null,
  email: null,
  phone: null,
};

const Contact = ({ address, email, pageTitle, phone }: Props): JSX.Element => {
  const theme = useTheme();
  const MUIStyles = makeStyles({
    socialLogoContainer: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    contactLink: {
      '&:hover': {
        '& span:first-child': {
          background: theme.palette.primary.dark,
        },
      },
    },
  })();

  return (
    <div className="col-12">
      <h1>{pageTitle}</h1>
      {email && (
        <p>
          <a
            className={`${MUIStyles.contactLink} ${Styles.contactLink}`}
            href={`mailto:${email}`}
          >
            <span
              className={`${MUIStyles.socialLogoContainer} ${Styles.socialLogoContainer}`}
            >
              <EmailIcon
                id="footerContactEmail"
                className={Styles.socialLogo}
              />
            </span>
            <span className={Styles.contactLinkText}>{email}</span>
          </a>
        </p>
      )}
      {phone && (
        <p>
          <a
            className={`${MUIStyles.contactLink} ${Styles.contactLink}`}
            href={`tel:${phone}`}
          >
            <span
              className={`${MUIStyles.socialLogoContainer} ${Styles.socialLogoContainer}`}
            >
              <PhoneIcon
                id="footerContactPhone"
                className={Styles.socialLogo}
              />
            </span>
            <span className={Styles.contactLinkText}>{phone}</span>
          </a>
        </p>
      )}
      {address && (
        <p className={Styles.contactLink}>
          <span
            className={`${MUIStyles.socialLogoContainer} ${Styles.socialLogoContainer}`}
          >
            <LocationIcon
              id="footerContactLocation"
              className={Styles.socialLogo}
            />
          </span>
          <span className={Styles.contactLinkText}>
            {address.line1}
            {address.line2 ? ` ${address.line2},` : ','} {address.city}{' '}
            {address.state}, {address.zip}
          </span>
        </p>
      )}
    </div>
  );
};

Contact.defaultProps = defaultProps;

export default Contact;
