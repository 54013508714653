import React from 'react';

interface Props {
  shippingPolicy: string;
}

const ShippingPolicy = ({ shippingPolicy }: Props): JSX.Element => (
  <div className="col-12">
    <h1>Shipping Policy</h1>
    <p>{shippingPolicy}</p>
  </div>
);

export default ShippingPolicy;
