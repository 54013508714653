import React from 'react';

interface Props {
  returnPolicy: string;
}

const ReturnPolicy = ({ returnPolicy }: Props): JSX.Element => (
  <div className="col-12">
    <h1>Return Policy</h1>
    <p>{returnPolicy}</p>
  </div>
);

export default ReturnPolicy;
