import React from 'react';
import { formatCurrencyDropZerosDecimal } from '../../utilities/Formatter';
import Styles from './Banner.module.scss';

const Banner = ({
  className,
  minimumOrderTotalToEarnFreeShipping,
}: {
  className?: string;
  minimumOrderTotalToEarnFreeShipping: number;
}): JSX.Element => {
  return (
    <div className={`${Styles.background} ${className}`}>
      <span className={Styles.boldUppercase}>Free Shipping</span> on all orders{' '}
      <span className={Styles.bold}>
        {formatCurrencyDropZerosDecimal(minimumOrderTotalToEarnFreeShipping)}
      </span>{' '}
      or more
    </div>
  );
};

export default Banner;
