import React from 'react';
import Store from '../../types/Store';
import FooterLinks from '../../types/FooterLinks';
import Contact from './Contact';
import LinkGroup from './LinkGroup';
import Styles from './Footer.module.scss';

type Props = { storeInfo: Store };

const Footer = ({ storeInfo }: Props): JSX.Element => {
  const defaultInformationLinks: FooterLinks[] = [
    { linkText: 'Shipping Policy', url: '/shipping-policy' },
    { linkText: 'Return Policy', url: '/return-policy' },
    { linkText: 'Contact', url: '/contact' },
  ];
  const defaultLegalLinks: FooterLinks[] = [];

  const {
    myStory: { pageName },
    customerContactInfo: { emailAddress, phoneNumber },
    tenant: {
      staticDocuments: { PrivacyPolicy, TermsOfUse },
    },
    byDesignRepDID,
    facebookAccount,
    instagramAccount,
    pinterestAccount,
    twitterAccount,
    youtubeAccount,
  } = storeInfo;

  if (pageName) {
    defaultInformationLinks.unshift({
      linkText: pageName,
      url: '/about',
    });
  }

  if (byDesignRepDID) {
    defaultInformationLinks.push({
      linkText: 'Join My Team',
      url: `https://paparazziaccessories.com/join/${byDesignRepDID}/#/`,
      external: true,
    });
  }

  if (PrivacyPolicy) {
    defaultLegalLinks.push({
      linkText: 'Privacy Policy',
      url: PrivacyPolicy,
      external: true,
    });
  }

  if (TermsOfUse) {
    defaultLegalLinks.push({
      linkText: 'Terms of Use',
      url: TermsOfUse,
      external: true,
    });
  }

  return (
    <div className={Styles.footer}>
      <div className="wrapper row mt-4 pt-5">
        <div className="col-12 col-md-6 order-md-2 mb-5 text-center text-md-end">
          <Contact
            emailAddress={emailAddress}
            phoneNumber={phoneNumber}
            facebookAccount={facebookAccount}
            instagramAccount={instagramAccount}
            pinterestAccount={pinterestAccount}
            twitterAccount={twitterAccount}
            youtubeAccount={youtubeAccount}
          />
        </div>
        <div className="col-6 col-md-3 mb-5">
          <LinkGroup header="Information" links={defaultInformationLinks} />
        </div>
        <div className="col-6 col-md-3 mb-5">
          <LinkGroup header="Legal" links={defaultLegalLinks} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
