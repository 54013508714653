import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CartItem from '../../components/CartItem';
import CheckoutPriceSummary from '../../components/CheckoutPriceSummary';
import LoadingSpinner from '../../components/LoadingSpinner';
import APIError from '../../components/APIError';
import CartContext from '../../context/CartContext';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import Styles from './Checkout.module.scss';

const Checkout = (): JSX.Element => {
  const retailerInfo = useContext(RetailerInformationContext);
  const cart = useContext(CartContext);
  const qualifiesForFreeShipping =
    retailerInfo?.store.minimumOrderTotalToEarnFreeShipping &&
    cart?.subtotal &&
    cart.subtotal >= retailerInfo.store.minimumOrderTotalToEarnFreeShipping;
  const hasFreeShippingRule = retailerInfo?.store.hasFreeShippingRule;
  const CheckoutDisabled = !cart?.items?.length;

  return (
    <div className="col-12">
      <div className="row justify-content-between">
        <div className="col-12 col-md-8 col-lg-6 mb-3">
          <h3 className="mb-5">Cart</h3>
          {!cart && <LoadingSpinner />}
          {cart instanceof Error && <APIError />}
          {cart && !(cart instanceof Error) && (
            <ul className={Styles.checkout_cart_list}>
              {cart?.items?.map((cartItem) => (
                <CartItem
                  key={`${cartItem.productId}${cartItem.claimCode || ''}`}
                  productGroupId={cartItem.productGroupId}
                  item={cartItem}
                  claimCode={cartItem.claimCode}
                  streamId={cartItem.streamId}
                  quantity={cartItem.quantity}
                  removeItem={cart.removeFromCart}
                  updateCartItem={cart.updateCartItem}
                />
              ))}
            </ul>
          )}
        </div>
        <div className="col-12 col-md-4">
          <h3 className="mb-5">Summary</h3>
          <ul className={Styles.checkout_cart_list}>
            <CheckoutPriceSummary
              subtotal={cart?.subtotal || 0}
              total={cart?.subtotal || 0}
              taxStrategy={retailerInfo?.store.taxStrategy}
              qualifiesForFreeShipping={
                !!qualifiesForFreeShipping && hasFreeShippingRule
              }
              displayFreeShippingThreshold
            />
          </ul>
          <Button
            data-testid="check-out-button"
            component={Link}
            to="/checkout/contact-shipping"
            color="primary"
            variant="contained"
            fullWidth
            disabled={CheckoutDisabled}
            className={Styles.linkHover}
          >
            Check out
          </Button>
          <Button
            data-testid="continue-shopping-button"
            component={Link}
            to="/shop"
            color="primary"
            variant="outlined"
            fullWidth
            className="mt-3"
          >
            Continue Shopping
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
