import React from 'react';
import Button from '@material-ui/core/Button';
import Styles from './PaginationControls.module.scss';

interface PaginationControlsProps {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  onChange: (currentPage: number) => void;
}

function PaginationControls({
  currentPage,
  itemCount,
  itemsPerPage,
  onChange,
}: PaginationControlsProps): JSX.Element {
  const totalPages = Math.ceil(itemCount / itemsPerPage) || 1;

  const previousPage = (): void => onChange(currentPage - 1);
  const nextPage = (): void => onChange(currentPage + 1);

  return (
    <div className={Styles.paginationControls}>
      <Button
        className={Styles.buttons}
        disabled={currentPage <= 1}
        variant="outlined"
        color="primary"
        size="small"
        onClick={previousPage}
      >
        Previous
      </Button>
      <span className={Styles.paginationControlsDetails}>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        className={Styles.buttons}
        disabled={currentPage >= totalPages}
        variant="outlined"
        color="primary"
        size="small"
        onClick={nextPage}
      >
        Next
      </Button>
    </div>
  );
}

export default PaginationControls;
