import clsx from 'clsx';
import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';
import { useTheme } from '@material-ui/core/styles';
import CartContext from '../../context/CartContext';
import { RangeFormatter } from '../../utilities/RangeFormatter';
import LoadingSpinner from '../../components/LoadingSpinner';
import QuantityPicker from '../../components/QuantityPicker';
import ShareMenu from '../../components/ShareMenu';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { useSession } from '../../context/SessionProvider';
import useProductGroupDetails from './useProductGroupDetails';
import useProductSelection from './useProductSelection';
import useSelectorsForProduct from './useSelectorsForProduct';
import Styles from './ProductDetail.module.scss';
import './override.react-image-gallery.scss';

type Props = {
  preselectedTags?: number[];
  toggleCartDisplay: () => void;
};

const ProductDetail: FunctionComponent<Props> = ({
  preselectedTags,
  toggleCartDisplay,
}) => {
  const theme = useTheme();
  const { id } = useParams() as { id: string };
  const productGroupId = Number(id);
  const retailerInfo = useContext(RetailerInformationContext);
  const { mutate } = useSelectorsForProduct(productGroupId);
  const cart = useContext(CartContext);
  const { sessionId } = useSession();
  const selection = useProductSelection(productGroupId, preselectedTags || []);
  const groupDetails = useProductGroupDetails(
    productGroupId,
    selection.selectedTags || []
  );
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (selection.selectedProduct) {
      const { quantityAvailable } = selection.selectedProduct;
      // We never want the max quantity to go below 1, even if the quantity
      // available is unpopulated, or populated with 0:
      const maxQuantity = Math.max(1, quantityAvailable ?? 0);

      // If we have a selection greater than the number available, reduce the
      // selected quantity (unless, again, it's less than 1)
      if (quantity > maxQuantity) {
        setQuantity(maxQuantity);
      }
    }
  }, [quantity, selection.selectedProduct]);

  useEffect(() => {
    if (mutate) {
      mutate();
    }
  }, [mutate, cart?.items]);

  const addItemQuantityToCart = useCallback(async () => {
    if (!cart || !selection.selectedProduct) {
      return;
    }

    toggleCartDisplay();

    if (mutate) {
      await mutate();
    }

    const itemInCart = cart.items?.find(
      (item) => item.productId === selection.selectedProduct?.productId
    );

    cart.updateCartItem(
      selection.selectedProduct.productId,
      itemInCart ? quantity + itemInCart.quantity : quantity,
      sessionId
    );
  }, [
    cart,
    quantity,
    selection.selectedProduct,
    sessionId,
    mutate,
    toggleCartDisplay,
  ]);

  if (selection.isError || groupDetails.isError) {
    throw selection.error || groupDetails.error;
  }

  if (!cart || !retailerInfo || selection.isLoading || groupDetails.isLoading) {
    return (
      <div className="col-12">
        <h2 className="mb-5">Product Detail</h2>
        <LoadingSpinner />
      </div>
    );
  }

  const { tenant, storeName } = retailerInfo.store;
  const {
    images,
    maxPrice,
    minPrice,
    productGroupName,
    productGroupDescription,
  } = groupDetails;

  const {
    categories,
    deselectTagCategory,
    selectTag,
    selectedProduct,
    anyAvailable,
  } = selection;

  const quantityInCart = cart.items?.filter(
    ({ productId: cartId }) => selection.selectedProduct?.productId === cartId
  )?.[0]?.quantity;

  return (
    <div className="col-12">
      <h2 className="mb-5">Product Detail</h2>
      <div className="row">
        <Helmet>
          <title>{`${tenant.name} | ${storeName}'s ${productGroupName} Product Details`}</title>
          <meta name="description" content={productGroupDescription} />
        </Helmet>
        <div className="col-md-6 col-lg-5 mb-3">
          <div className={Styles.imageGalleryWrapper}>
            <ImageGallery
              additionalClass={clsx('image-gallery-override', {
                igo_contain: true,
                igo_cover: false,
              })}
              disableKeyDown
              items={images}
              showPlayButton={false}
              showFullscreenButton={false}
              lazyLoad
            />
            <div className={Styles.imageGalleryShareIcon}>
              <ShareMenu
                title={`${tenant.name} | ${storeName} ${productGroupName} product details.`}
                text={`${tenant.name} | ${storeName} ${productGroupName} product details.`}
                emailBody={`Checkout ${storeName} ${productGroupName} for ${tenant.name}`}
                emailSubject={`Checkout ${storeName} ${tenant.name} Store`}
                image={images[0].original}
              />
            </div>
          </div>
        </div>
        {productGroupName ? (
          <div className="col-md-6">
            <h3 className="mb-1">{productGroupName}</h3>
            {(minPrice > 0 || maxPrice > 0) && (
              <div className={`${Styles.price} mb-1`}>
                {RangeFormatter.format(minPrice, maxPrice)}
              </div>
            )}
            <div className={clsx('mb-4', Styles.description)}>
              {productGroupDescription}
            </div>
            <div className="mb-4">
              {!anyAvailable ? (
                <h5 style={{ color: theme.palette.error.main }}>
                  We are sorry, this item is out of stock.
                </h5>
              ) : (
                categories.map((category) => {
                  const { categoryId, categoryName, options, selectedTagId } =
                    category;
                  return (
                    <div className="mb-3" key={categoryId}>
                      <h4 className="mb-3 pt-3">{categoryName}</h4>
                      <ToggleButtonGroup
                        data-testid="details-tagButtonGroup"
                        key={categoryId}
                        exclusive
                        size="small"
                        onChange={(_, value: number | null) =>
                          value === null
                            ? deselectTagCategory(categoryId)
                            : selectTag(value)
                        }
                        className={Styles.toggleButtonGroup}
                        value={selectedTagId}
                      >
                        {options.map(
                          ({ availabililty, selected, tagId, tagName }) => (
                            <ToggleButton
                              data-testid="details-tagButton"
                              key={tagId}
                              value={tagId}
                              disabled={
                                !selected && availabililty === 'unavailable'
                              }
                              className={clsx({
                                [Styles.toggleButton]: true,
                                [Styles.partiallyAvailable]:
                                  !selected &&
                                  availabililty === 'only_with_other_options',
                              })}
                              selected={selected}
                            >
                              {tagName}
                            </ToggleButton>
                          )
                        )}
                      </ToggleButtonGroup>
                    </div>
                  );
                })
              )}
            </div>
            {!selectedProduct && anyAvailable && (
              <h5 style={{ color: theme.palette.grey[600] }}>
                Please select an option from each section above to add to cart
              </h5>
            )}
            <div>
              {selectedProduct && (
                <>
                  {!selectedProduct.quantityAvailable && anyAvailable ? (
                    <h5 style={{ color: theme.palette.error.main }}>
                      We are sorry, this item is out of stock.
                    </h5>
                  ) : (
                    selectedProduct.quantityAvailable === 1 && (
                      <h5 style={{ color: theme.palette.secondary.main }}>
                        Only {selectedProduct.quantityAvailable} left!
                      </h5>
                    )
                  )}
                  {quantityInCart && (
                    <h5 style={{ color: theme.palette.secondary.main }}>
                      You have {quantityInCart} in your cart
                    </h5>
                  )}
                  <div
                    className={clsx('row', {
                      [Styles.disabledColor]:
                        !selectedProduct || !selectedProduct.quantityAvailable,
                    })}
                  >
                    <p className="col-12 fw-bold">Quantity</p>
                    <div className="col-6">
                      <QuantityPicker
                        quantity={quantity}
                        minimumQuantity={1}
                        maximumQuantity={
                          selectedProduct?.quantityAvailable || 1
                        }
                        onAfterDecrement={(newQuantity) =>
                          setQuantity(newQuantity)
                        }
                        onAfterIncrement={(newQuantity) =>
                          setQuantity(newQuantity)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row mb-4">
                <div className="col-12 col-sm-6">
                  <Button
                    data-testid="add-to-cart-btn"
                    style={{ marginTop: '1rem' }}
                    color="primary"
                    fullWidth
                    disabled={
                      !selectedProduct ||
                      (selectedProduct &&
                        selectedProduct?.quantityAvailable < 1)
                    }
                    variant="contained"
                    onClick={addItemQuantityToCart}
                  >
                    Add to Cart
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6">
            <h3 className="mb-1">Product Not Found</h3>
            <h5 style={{ color: theme.palette.error.main }}>
              We are sorry, this product can not be found
            </h5>
            <p>
              Try searching for the product in my store or confirm that the URL
              you were given is correct.
            </p>
            <Button
              component={Link}
              to="/shop"
              color="primary"
              variant="contained"
            >
              Continue Shopping
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
