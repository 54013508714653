import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CartContext from '../../context/CartContext';
import useAlerts from '../../context/Alerts/useAlerts';
import { useSession } from '../../context/SessionProvider';
import MyStory from '../../types/MyStory';
import ShoppingCart from '../ShoppingCart';
import OutsideAlerter from '../ClickOutside';
import CountdownTimer from '../CountdownTimer';
import IconShoppingCart from '../Icons/IconShoppingCart';
import Styles from './Navigation.module.scss';

interface Props {
  dsoName?: string;
  dsoLogoUrl?: string;
  myStoryPage?: MyStory;
  storeName?: string;
  byDesignRepDID?: string;
  toggleCartDisplay: () => void;
  displayCart: boolean;
}

const Navigation = ({
  dsoLogoUrl,
  dsoName,
  myStoryPage,
  storeName,
  byDesignRepDID,
  toggleCartDisplay,
  displayCart,
}: Props): JSX.Element => {
  const cart = useContext(CartContext);
  const { generateNewSessionId } = useSession();

  const theme = useTheme();
  const muiStyles = makeStyles({
    navLink: {
      '& span': {
        '&:hover': {
          borderBottom: `3px solid ${theme.palette.primary.main}`,
        },
      },
    },
    activeLink: {
      '& span': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
      },
    },
  })();

  const navLinks: {
    url: string;
    linkText: string;
    external?: boolean;
  }[] = [
    {
      linkText: storeName ? `${storeName}'s Store` : 'Store',
      url: '/shop',
    },
    { linkText: 'Register', url: '/register' },
  ];

  if (
    myStoryPage &&
    Object.values(myStoryPage).some((x) => x !== null && x !== '')
  ) {
    navLinks.push({
      linkText: myStoryPage.pageName || 'My Story',
      url: '/about',
    });
  }

  if (byDesignRepDID) {
    navLinks.push({
      linkText: 'Join My Team',
      url: `https://paparazziaccessories.com/join/${byDesignRepDID}/#/`,
      external: true,
    });
  }

  const [displayMobileNav, setDisplayMobileNav] = useState(false);
  const [inTransition, setInTransition] = useState(false);
  const [totalQuantities, setTotalQuantities] = useState<number>();
  const { sendAlert } = useAlerts();

  useEffect(() => {
    if (cart?.validationMessage) {
      sendAlert(cart.validationMessage, 'error');
    }

    if (cart?.items && cart.items?.length > 0) {
      let total = 0;
      cart.items.forEach((cartItem) => {
        total += cartItem.quantity;
      });
      setTotalQuantities(total);
    }
  }, [cart, sendAlert]);

  const closeMenu = (): void => {
    if (displayMobileNav) setInTransition(true);
    setDisplayMobileNav(false);
    setTimeout(() => {
      setInTransition(false);
      document.body.classList.remove('overflow-hidden');
    }, 300); // 0.3s for css animation duration
  };

  const toggleMenu = (): void => {
    // eslint-disable-next-line no-unused-expressions
    document.body.classList.contains('overflow-hidden')
      ? document.body.classList.remove('overflow-hidden')
      : document.body.classList.add('overflow-hidden');

    // eslint-disable-next-line no-unused-expressions
    displayMobileNav ? closeMenu() : setDisplayMobileNav(true);
  };

  const dsoLogo = dsoLogoUrl ? (
    <img
      src={dsoLogoUrl}
      alt={`${dsoName || 'Store'} Logo`}
      className="d-inline-block"
    />
  ) : (
    <Skeleton count={1} className={Styles.nav_logo__loading} />
  );

  return (
    <div
      className={clsx(Styles.nav_background, {
        [Styles.mobile_nav]: displayMobileNav,
        [Styles.mobile_nav__transition]: inTransition,
      })}
    >
      <Button
        className={`${Styles.menu_button} d-md-none position-absolute`}
        onClick={toggleMenu}
        startIcon={
          <div className={Styles.hamburger_icon}>
            <span />
            <span />
            <span />
          </div>
        }
      />
      <NavLink
        to="/shop"
        className={`${Styles.nav_link} ${Styles.nav_logo} ${Styles.nav_logo_mobile} d-md-none`}
      >
        {dsoLogo}
      </NavLink>
      <div className={Styles.nav_wrapper}>
        <nav className={Styles.nav}>
          <ul>
            <li>
              <NavLink
                to="/shop"
                className={`${Styles.nav_link} ${Styles.nav_logo}`}
                onClick={closeMenu}
              >
                {dsoLogo}
              </NavLink>
            </li>
            {navLinks?.map((link) =>
              storeName ? (
                <li key={link.linkText}>
                  <NavLink
                    target={link.external ? '_blank' : '_self'}
                    to={link.url}
                    className={({ isActive }) =>
                      clsx(Styles.nav_link, muiStyles.navLink, {
                        [muiStyles.activeLink]: isActive,
                      })
                    }
                    onClick={closeMenu}
                  >
                    <span className={Styles.nav_link__text}>
                      {link.linkText}
                    </span>
                  </NavLink>
                </li>
              ) : (
                <li key={link.linkText} className={Styles.nav_link}>
                  <span className={Styles.nav_link__text}>
                    <Skeleton
                      count={1}
                      className={`${Styles.nav_link__loading} d-inline-block`}
                      width={150}
                    />
                  </span>
                </li>
              )
            )}
          </ul>
        </nav>
        <OutsideAlerter
          clickOutside={toggleCartDisplay}
          displayCart={displayCart}
        >
          <div className={Styles.cart_wrapper}>
            <Button
              color="primary"
              variant={
                cart && cart.items && cart.items.length > 0
                  ? 'contained'
                  : 'text'
              }
              className={clsx('mx-3', Styles.nav_cart_button, {
                [Styles.nav_cart_button__active]:
                  cart && cart.items && cart.items.length > 0,
              })}
              onClick={toggleCartDisplay}
            >
              {cart?.items && cart.items.length > 0 && (
                <>
                  {cart?.expires && (
                    <CountdownTimer
                      className={Styles.timer}
                      expirationDate={cart.expires}
                      onTimeZero={() => {
                        if (cart && cart.items) generateNewSessionId();
                      }}
                    />
                  )}
                  {totalQuantities && (
                    <span className="ms-3 me-2">
                      {totalQuantities}
                      <span className="d-none d-sm-inline">
                        {' '}
                        Item
                        {totalQuantities > 1 && 's'}
                      </span>
                    </span>
                  )}
                </>
              )}
              <IconShoppingCart id="navigationShoppingCartIcon" />
            </Button>
            {displayCart && cart && (
              <div className={Styles.shoppingCartPreview}>
                <ShoppingCart handleClick={toggleCartDisplay} />
              </div>
            )}
          </div>
        </OutsideAlerter>
      </div>
      <div className={Styles.close_background} onClick={closeMenu} />
    </div>
  );
};

export default Navigation;
