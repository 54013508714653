import useSWR from 'swr';
import * as Sentry from '@sentry/react';
import { baseUrl, apiVersion } from '../apiConfig';
import InventoryResponse from '../../responses/InventoryResponse';
import CartValidationType from '../../../types/CartValidationType';
import CartType from '../../../types/CartType';
import RewardsTypes from '../../../types/RewardsTypes';
import InventorySearchParams from '../../../types/InventorySearchParams';

export async function fetcher<JSON>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      Sentry.captureMessage(
        `Error - ${res.status}: SWR fetcher - ${res.statusText}`
      );
    });
    throw new Error(`Response: ${res.status} ${res.statusText}`);
  }

  return res.json();
}

export const useGetInventorySearch = (
  tenantId: number | undefined,
  storeId: number | undefined,
  body: InventorySearchParams
): {
  inventorySearch: InventoryResponse | undefined;
  isLoading: boolean;
  isError: Error | undefined;
} => {
  const { data, error } = useSWR<InventoryResponse, Error>(
    [
      `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/inventory/search`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    ],
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    inventorySearch: data,
    isLoading: !error && !data,
    isError: error && new Error('Unable to retrieve product catalog.'),
  };
};

export const useGetCartValidate = (
  tenantId: number | undefined,
  storeId: number | undefined,
  sessionId: string
): {
  cart: CartValidationType;
  isLoading: boolean;
  isError: Error | undefined;
} => {
  const { data, error } = useSWR<CartValidationType | undefined, Error>(
    storeId
      ? `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/${sessionId}/validate`
      : null,
    fetcher,
    {
      refreshInterval: 60000, // 1 minute refresh interval
    }
  );

  const cartValue = data || {
    validationMessage: '',
    cart: {
      sessionId: '',
      expires: '',
      cartItems: [],
      cartWeightInOunces: 0,
      subtotal: 0,
      estimatedShippingAmount: 0,
      claimFailureMessage: '',
    },
  };

  return {
    cart: cartValue,
    isLoading: !error && !data,
    isError: error && new Error('Unable to retrieve shopping cart.'),
  };
};

export const useGetCart = (
  tenantId: number | undefined,
  storeId: number | undefined,
  sessionId: string
): { cart: CartType; isLoading: boolean; isError: Error | undefined } => {
  const { data, error } = useSWR<CartType | undefined, Error>(
    `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/${sessionId}`,
    fetcher
  );

  const cartValue = data || {
    sessionId: '',
    expires: '',
    cartItems: [],
    cartWeightInOunces: 0,
    subtotal: 0,
    estimatedShippingAmount: 0,
    claimFailureMessage: '',
  };

  return {
    cart: cartValue,
    isLoading: !error && !data,
    isError: error && new Error('Unable to retrieve shopping cart.'),
  };
};

export const useGetRewards = (
  customerGuid: string
): {
  rewards: RewardsTypes | undefined;
  isLoading: boolean;
  isError: Error | undefined;
} => {
  const { data, error } = useSWR<RewardsTypes | undefined, Error>(
    `${baseUrl}/${apiVersion}/storeCustomers/${customerGuid}/rewards`,
    fetcher
  );

  return {
    rewards: data,
    isLoading: !error && !data,
    isError: error && new Error('Unable to retrieve rewards.'),
  };
};

const ShoppingApi = { useGetCartValidate };

export default ShoppingApi;
